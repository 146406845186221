

// src/config.ts
interface Config {
  VUE_APP_API_URL: string,
  VUE_APP_ENERGY_ADDRESS: string,
  VUE_APP_EXCHANGE_ADDRESS: string,
  VUE_APP_TRXGASSPROPORTION: string,
  VUE_APP_ENERGY_PRICE_LEFT:string,
  VUE_APP_ENERGY_PRICE_RIGHT:string,
  VUE_APP_ENERGY_BALANCE:string
}

let config: Config;

export async function loadConfig(): Promise<Config> {
  if (config) {
    return config;
  }

  const response = await fetch('/config.json');
  if (!response.ok) {
    throw new Error('Failed to load config.json');
  }

  config = await response.json();
  return config;
}
