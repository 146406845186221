import axios from 'axios'
import { JSEncrypt } from 'jsencrypt'

const api = axios.create({
  headers: {
    'Content-Type': 'application/json',
    'Accept': 'application/json',
    'Access-Control-Allow-Origin': '*',
  },
});

api.interceptors.request.use(config => {
  const mRsa = new JSEncrypt({ default_key_size: '1024' })
  mRsa.setPublicKey(`-----BEGIN RSA PUBLIC KEY-----
MIGJAoGBAIteft/qmZ7ewpjpvuByIazAqzYGyP1i9z0Q57H/GX8w814PWpcHVD6Z
oH0QAwV1Ea2fPFvkKhoClcJHkpS1aSVh+YAkMW1TrFR8z/C1c+DjAWUThHL9TUvD
+IDhMluJNTIdH4RSVwP2cud/ans0ajT2f3AyKQOc1W1FYWtysMvZAgMBAAE=
-----END RSA PUBLIC KEY-----`)
  config.data = {
    encrypt: mRsa.encrypt(JSON.stringify(config.data))
  }
  return config
})

export default api
