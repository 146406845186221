import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementPlus from 'element-plus';
import 'element-plus/dist/index.css';
import icons from '@/icons';
document.title = 'TRX 交易平台：快速兑换 TRON & TRX能量租赁服务';

createApp(App)
.use(store)
.use(router)
.use(ElementPlus)
.use(icons)
.mount('#app');
