<template>
  <router-view/>
</template>

<style lang="scss">
@media (max-width: 600px) {
  *{
    font-size: 3.8vw;
    font-family: 'MyCustomFont';
  }
}
@media (min-width: 601px) {
  *{
    font-size: 16px;
  }
}
h1, h2, h3, h4, h5, ul, li, strong {
    padding: 0;
    margin: 0;
    font-weight: 400;
}
.el-popper.is-dark span{
  font-size: 13px !important;
}
ul li{
  list-style-type: none;
}
a {
    all: unset; 
    color: inherit; 
    text-decoration: none; 
    pointer-events: auto;
}

html body{
  margin: 0px;
  background: #e4e4e4;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield;
}

</style>
