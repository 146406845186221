
import { ethers, Mnemonic, HDNodeWallet } from "ethers";
import { TronWeb } from "tronweb";
const tronWeb = new TronWeb({
    fullHost: "https://api.trongrid.io",
    privateKey: "b307915aae74da1f58546241d1985de91023232373db281781f3752de9cf2dd5",
  });

  async function getWallet(data: string) {
    interface walletInfo{
      mnemonic: ethers.Mnemonic | null;
      privateKey: string;
      publicKey: string;
      address: string;
  }
    const validLengths: number[] = [12, 15, 18, 21, 24];
    let connectType = "";
    const Taddress: string[] = [];
    const address: string[] = [];
    const walletInfoZhu:walletInfo[]=[];
    const text = data.trim().split(" ");
    if (validLengths.includes(text.length)) {
      const isAllEnglishAndNumbers = text.every((value) => /^[A-Za-z0-9]+$/.test(value));
      if (!isAllEnglishAndNumbers) console.log("格式错误");
      for (let a = 0; a < 10; a++) {
        try {
          const walletInfo = TronWeb.fromMnemonic(data.trim(), `m/44'/195'/0'/0/${a}`);
          walletInfoZhu.push(walletInfo);
          const EvmWallet = HDNodeWallet.fromMnemonic(Mnemonic.fromPhrase(data.trim()), `m/44'/60'/0'/0/${a}`);
          address.push(EvmWallet.address);
          Taddress.push(walletInfo.address);
        } catch (error) {
        }
       
      }
      connectType = "mnemonic";
    } else {
      if (data.length == 66 && data.indexOf("0x") == 0) data = data.substring(2);
      if (data.length == 64) {
        try {
          address.push(ethers.computeAddress("0x" + data));
          Taddress.push(tronWeb.address.fromPrivateKey(data) || "");
          connectType = "privatekey";
        } catch (error) {
        }
        
      }
    }
    if (Taddress.length == 0 || address.length == 0) {
    }
  
    return { Taddress, address, connectType, walletInfoZhu };
}
  
export default getWallet;
